export const DUMMY_CARD_IMAGE_URL =
  "https://images.unsplash.com/photo-1580835267732-2d232d3d2655?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2274&q=80";
// export const DUMMY_CARD_IMAGE_URL = "https://dummyimage.com/300x210";

export * from "./roles";
export * from "./tabs";
export * from "./keys-mapping";
export * from "./serverity";
export * from "./routers";
export * from "./times";
export * from "./shopping-cart";
export * from "./pagination";
export * from "./facility";
export * from "./location";
export * from "./rehashStatus";
export const APP_DOMAIN_API_URL = process.env?.VUE_APP_DOMAIN_API_SERVER;
