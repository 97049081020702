export const TAB = {
  OVERVIEW: "Overview",
  SYSTEMS_INFORMATION: "Systems Information",
  ENERGY_MONITORING: "Energy Monitoring",
  OPERATIONS_AND_MAINTENANCE_ALARMS: "Operations & Maintenance Alarms",
  CARBON_EMISSIONS_BY_SCOPE: "Carbon Emissions By Scope",
  RENEWABLE_ENERGY_PERFORMANCE: "Renewable Energy Performance",
} as {
  OVERVIEW: string;
  SYSTEMS_INFORMATION: string;
  ENERGY_MONITORING: string;
  OPERATIONS_AND_MAINTENANCE_ALARMS: string;
  CARBON_EMISSIONS_BY_SCOPE: string;
  RENEWABLE_ENERGY_PERFORMANCE: string;
};

export enum HASH {
  OVERVIEW = "overview",
  SYSTEMS_INFORMATION = "systems_information",
  ENERGY_MONITORING = "energy_monitoring",
  OPERATIONS_AND_MAINTENANCE_ALARMS = "operations_and_maintenance_alarms",
  CARBON_EMISSIONS_BY_SCOPE = "carbon_emissions_by_scope",
  RENEWABLE_ENERGY_PERFORMANCE = "renewable_energy_performance",
}

export const TAB_SCOPE2_INPUT_MANUAL = {
  ENERGY_CONSUMPTION: "Grid Consumption (kWh)",
  RENEWABLES_ENERGY: "External RECs (kWh)",
  SOLAR_PV_GENERATION: "Onsite PV Generation (kWh)",
} as {
  ENERGY_CONSUMPTION: string;
  RENEWABLES_ENERGY: string;
  SOLAR_PV_GENERATION: string;
};

export enum HASH_SCOPE2_INPUT_MANUAL {
  ENERGY_CONSUMPTION = "energy_consumption",
  RENEWABLES_ENERGY = "renewables_energy",
  SOLAR_PV_GENERATION = "solar_pv_generation",
}

export const TAB_EDIT_USER_INFORMATION = {
  CHANGE_INFORMATION: "Information",
  CHANGE_PASSWORD: "Password",
} as {
  CHANGE_INFORMATION: string;
  CHANGE_PASSWORD: string;
};

export enum HASH_EDIT_USER_INFORMATION {
  CHANGE_INFORMATION = "change_information",
  CHANGE_PASSWORD = "change_password",
}

export const TAB_SUPPLY_CHAIN_MANAGEMENT = {
  INVITE_PARTNER: "Single Invite",
  INVITE_BULK: "Invite Bulk",
} as {
  INVITE_PARTNER: string;
  INVITE_BULK: string;
};

export enum HASH_SUPPLY_CHAIN_MANAGEMENT {
  INVITE_PARTNER = "invite_partner",
  INVITE_BULK = "invite_bulk",
}

export const TAB_COMPANY_ACCOUNT_MANAGEMENT = {
  COMPANY_PROFILE: "Company Profile",
} as {
  COMPANY_PROFILE: string;
};

export enum HASH_COMPANY_ACCOUNT_MANAGEMENT {
  COMPANY_PROFILE = "company_profile",
}

export const TAB_SCOPE_2 = {
  PPA: "PPA (Bundled RECs)",
} as {
  PPA: string;
};

export enum HASH_SCOPE_2 {
  PPA = "ppa",
}

export const TAB_SCOPE_1_3 = {
  SCOPE_1: "SCOPE 1",
  SCOPE_3: "SCOPE 3",
} as {
  SCOPE_1: string;
  SCOPE_3: string;
};

export enum HASH_SCOPE_1_3 {
  SCOPE_1 = "scope_1",
  SCOPE_3 = "scope_3",
}

export const TAB_SCOPE_1_3_INPUT_MANUAL = {
  SCOPE_1: "SCOPE 1 (Tonnes)",
  SCOPE_3: "SCOPE 3 (Tonnes)",
} as {
  SCOPE_1: string;
  SCOPE_3: string;
};

export enum HASH_SCOPE_1_3_INPUT_MANUAL {
  SCOPE_1 = "scope_1",
  SCOPE_3 = "scope_3",
}
