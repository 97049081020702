export enum KEYS_MAPPING {
  YEAR = "Year",
  CO2EMISSIONS = "CO2Emissions",
  TARGET_COMBINED_CO2EMISSIONS = "TargetCombinedCO2Emissions",
  MONTH = "Month",
  RENEWABLES_ENERGY_USAGE = "RenewablesEnergyUsage",
  SCOPE2_ENERGY_USAGE = "Scope2EnergyUsage",
  RENEWABLES_ENERGY_USAGE_PERCENTAGE = "RenewablesEnergyUsagePercentage",
  RENEWABLES_TARGET_PERCENTAGE = "RenewablesTargetPercentage",
}

export enum KEYS_QUERY {
  DAY = "day",
  SEVEN_DAY = "7-day",
  MONTH = "month",
  MONTH_TO_DATE = "mtd",
  YEAR_TO_DATE = "ytd",
}

export const PerformanceOverviewRenewable = {
  SelfGenerated: {
    label: "Onsite PV",
    color: "#C9E0BA",
  },
  PowerPurchaseAgreement: {
    label: "PPA",
    color: "#5D8F3B",
  },
  RecPurchased: {
    label: "RECs",
    color: "#384C29",
  },
};

export const PerformanceOverviewConsumed = {
  GridConsumption: {
    label: "Total Consumption",
    color: "#808080",
  },
  Renewables: {
    label: "Renewable Energy",
    color: "#8EC63F",
  },
};
