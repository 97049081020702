import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-end" }
const _hoisted_2 = ["disabled"]
const _hoisted_3 = { class: "flex" }
const _hoisted_4 = ["disabled"]
const _hoisted_5 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode($setup["Form"], {
      as: "el-form",
      "validation-schema": $setup.schemaEmail,
      onSubmit: $setup.onSubmitEmail,
      "label-position": "top",
      class: "mb-6",
      autocomplete: "off"
    }, {
      default: _withCtx(({ isSubmitting }) => [
        _createElementVNode("div", {
          class: _normalizeClass([
        'space-y-4 2xl:space-y-6',
        isSubmitting && 'isSubmitting-rounded',
      ])
        }, [
          _createVNode($setup["Field"], { name: "Email" }, {
            default: _withCtx(({ value, field, errorMessage }) => [
              _createVNode(_component_el_form_item, {
                class: "inputfield",
                error: errorMessage,
                label: "Reset Password through OTP"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, _mergeProps({ placeholder: "Reset Password through OTP" }, field, { "model-value": value }), null, 16, ["model-value"])
                ]),
                _: 2
              }, 1032, ["error"])
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("button", {
              type: "submit",
              class: "w-52 h-12 items-center btn btn--green-primary",
              disabled: isSubmitting
            }, " Send OTP ", 8, _hoisted_2)
          ])
        ], 2)
      ]),
      _: 1
    }, 8, ["validation-schema"]),
    _createVNode($setup["Form"], {
      as: "el-form",
      "validation-schema": $setup.schemaOTP,
      onSubmit: $setup.onSubmit,
      "label-position": "top"
    }, {
      default: _withCtx(({ isSubmitting }) => [
        _createElementVNode("div", {
          class: _normalizeClass([
        'space-y-4 2xl:space-y-6',
        isSubmitting && 'isSubmitting-rounded',
      ])
        }, [
          _createVNode($setup["Field"], { name: "OTP" }, {
            default: _withCtx(({ value, field, errorMessage }) => [
              _createVNode(_component_el_form_item, {
                class: "inputfield",
                error: errorMessage
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, _mergeProps({ placeholder: "Key in OTP" }, field, { "model-value": value }), null, 16, ["model-value"])
                ]),
                _: 2
              }, 1032, ["error"])
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("button", {
              type: "reset",
              class: "w-1/2 btn bg-white text-black",
              disabled: isSubmitting
            }, " Cancel ", 8, _hoisted_4),
            _createElementVNode("button", {
              type: "submit",
              class: "w-1/2 btn btn--green-primary",
              disabled: isSubmitting
            }, " Next ", 8, _hoisted_5)
          ])
        ], 2)
      ]),
      _: 1
    }, 8, ["validation-schema"])
  ], 64))
}