import store from "@/store";
import AccountModule from "@/store/modules/Account";
import PrivateRoutes from "@ems/pages/PrivateRoutes.vue";
import NProgress from "nprogress";
import "nprogress/nprogress.css"; // progress bar style
import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import LoginPage from "@ems/pages/Login.vue";
import ForgotPassword from "@ems/pages/ForgotPassword.vue";
import UpdatePassword from "@ems/pages/UpdatePassword.vue";

NProgress.configure({
  showSpinner: false,
  easing: "ease",
  speed: 500,
  trickleSpeed: 800,
});

if (
  store.state.LocalStorage &&
  "token" in store.state.LocalStorage &&
  !!store.state.LocalStorage.token
) {
  AccountModule.loginSuccess(store.state.LocalStorage.token);
  const isAuthenticated = AccountModule.getUserInfo?.isAuthenticated;
  if (isAuthenticated) {
    //AccountModule.getAccountProfile();
  }
}

const view = (name: string) => () =>
  import(/* webpackChunkName: "[request]" */ `@ems/pages/${name}.vue`);

// eslint-disable-next-line @typescript-eslint/no-var-requires
const ROUTES = (name: string) => require(`@ems/pages/${name}/routes`).default;

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    meta: {
      withAuth: true,
      permission: AccountModule.getRole,
      layout: "default",
    },
    component: PrivateRoutes,
    children: ROUTES(AccountModule.getRole),
  },
  {
    path: "/login",
    name: "Login",
    meta: { layout: "blank" },
    component: LoginPage,
  },
  {
    path: "/redeem-invitation",
    name: "Redeem invitation",
    meta: { layout: "blank" },
    component: view("RedeemInvitation"),
  },
  {
    path: "/redeem-registration",
    name: "Redeem registration",
    meta: { layout: "blank" },
    component: view("Registration"),
  },
  {
    path: "/profile",
    name: "Profile",
    component: view("ClientAdmin/Profile"),
  },
  {
    path: "/forget-password",
    name: "ForgetPassword",
    meta: { layout: "blank" },
    component: ForgotPassword,
  },
  {
    path: "/update-password",
    name: "UpdatePassword",
    meta: { layout: "blank" },
    component: UpdatePassword,
  },
  {
    path: "/:404(.*)*",
    name: "NotFound",
    meta: { layout: "blank" },
    component: view("NotFound"),
  },
];

const router = createRouter({
  linkActiveClass: "active",
  linkExactActiveClass: "exact-active",
  history: createWebHashHistory(process.env.BASE_URL),
  scrollBehavior(to, _from, savedPosition) {
    if (to.hash) {
      return savedPosition || {};
    }

    return savedPosition || { top: 0 };
  },
  routes,
});

router.beforeEach(async (to) => {
  NProgress.start();
  const isAuthenticated = AccountModule.getUserInfo?.isAuthenticated;
  if ("withAuth" in to.meta && to.meta.withAuth && !isAuthenticated) {
    return { name: "Login", query: { redirect: to.fullPath } };
  }
});

router.afterEach(() => {
  NProgress.done();
});

export default router;
