import { IPagination } from "@/models/Pagination";
import {
  BuyOderItemDTO,
  BuyOderRequestDTO,
  BuyRecDTO,
  IBuyOrder,
  IRenewableDTO,
  IRenewableOptionDetail,
  IRenewableOptionItemDetail,
  IRenewablePaginationToBuyDTO,
  IRenewableParameters,
  IRenewableToBuyDTO,
  IRenewableToBuyParameters,
  IStandards,
} from "@/models/RenewableOptions";
import { ErrorResponse } from "@/services/axios/error";
import { RenewableOptionAPI } from "@/services/renewableoptions";
import store from "@/store";
import { handlePagination } from "@/utils/helpers/paginateData";
import { MAX_RECS, PAGE_SIZE_BUY_RECS } from "@ems/constants";
import { ElMessage } from "element-plus/lib/components/message";
import isEmpty from "lodash/isEmpty";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
const name = "RenewableOptionsModule";

if (store.state[name]) {
  store.unregisterModule(name);
}
@Module({ dynamic: true, name, namespaced: true, store })
class RenewableOptionsModule extends VuexModule {
  private errorRenewAbleOption = {} as ErrorResponse;
  private errorRenewableBuyList = {} as ErrorResponse;
  private errorEnergyType = {} as ErrorResponse;
  private errorStandardsType = {} as ErrorResponse;
  private errorEWallet = {} as ErrorResponse;
  private errorBuyRECs = {} as ErrorResponse;

  private parameterRenewableOptions = {} as IRenewableToBuyParameters;
  private renewAbleOptions = [] as IRenewableDTO[];
  private isLoadingRenewAbleOption = false;
  private isLoadingDownLoad = false;
  private shoppingCart = [] as BuyOderItemDTO[];
  private retireCart = [] as IRenewableToBuyDTO[];

  private buyListPagination = {} as IPagination;
  private renewableBuyList = [] as IRenewableToBuyDTO[];
  private isLoadingRenewableBuyList = false;
  private pagination = {} as IPagination;
  private tempRenewableBuyList = [] as IRenewableToBuyDTO[];
  private renewableOptionDetails = {} as IRenewableOptionDetail;
  private isRenewableOptionDetails = false as boolean;
  private isLoadingGetCartItems = false as boolean;
  private energyType = [] as string[];
  private standardsType = [] as IStandards[];
  private isStandardsLoading = false;

  private isLoadingBuyRECs = false;

  get isLoadingDownLoadGetter() {
    return this.isLoadingDownLoad;
  }
  get buyListPaginationGetter() {
    return this.buyListPagination;
  }
  get isLoadingRenewAbleOptionGetter() {
    return this.isLoadingRenewAbleOption;
  }
  get renewAbleOptionsGetter() {
    return this.renewAbleOptions;
  }
  get isRenewableOptionDetailsGetter() {
    return this.isRenewableOptionDetails;
  }

  get renewableOptionDetailsGetter() {
    return this.renewableOptionDetails;
  }
  get isLoadingRenewAbleOptionDetailsGetter() {
    return this.isLoadingRenewAbleOption;
  }

  get renewableBuyListGetter() {
    return this.renewableBuyList;
  }
  get isLoadingRenewableBuyListGetter() {
    return this.isLoadingRenewableBuyList;
  }
  get shoppingCartGetter() {
    return this.shoppingCart;
  }

  get retireCartGetter() {
    return this.retireCart;
  }

  get energyTypeGetter() {
    return this.energyType;
  }

  get standardsTypeGetter() {
    return this.standardsType;
  }

  get parameterRenewableOptionsGetter() {
    return this.parameterRenewableOptions;
  }

  get isLoadingBuyRECsGetter() {
    return this.isLoadingBuyRECs;
  }
  get isLoadingGetCartItemsGetter() {
    return this.isLoadingGetCartItems;
  }
  @Mutation
  setLoadingRenewAbleOption(isLoading: boolean) {
    this.isLoadingRenewAbleOption = isLoading;
  }
  @Mutation
  getRenewableOptionError(error: ErrorResponse) {
    this.errorRenewAbleOption = error;
    this.isLoadingRenewAbleOption = false;
  }

  @Mutation
  getRenewableOptionSuccess(data: any) {
    if (!isEmpty(data)) {
      const { Collection, ...rest } = data;
      this.renewAbleOptions = Collection;
      this.pagination = rest;
      this.errorRenewAbleOption = {} as ErrorResponse;
    } else {
      this.renewAbleOptions = [];
    }

    this.isLoadingRenewAbleOption = false;
  }
  @Action({ rawError: true })
  async getRenewableOptionsAction(payload?: IRenewableParameters) {
    const serviceRenewableOptionAPI = new RenewableOptionAPI(
      "",
      payload?.AccountId
    );
    this.setLoadingRenewAbleOption(true);

    const { data, error } = await serviceRenewableOptionAPI.getRenewableOptions(
      payload
    );
    if (error) {
      this.getRenewableOptionError(error);
    } else {
      this.getRenewableOptionSuccess(data);
    }
  }

  @Mutation
  setLoadingBuyList(isLoading: boolean) {
    this.isLoadingRenewableBuyList = isLoading;
  }
  @Mutation
  emptyPagination() {
    this.buyListPagination = {} as IPagination;
  }
  @Mutation
  addCartError(error?: ErrorResponse) {
    this.isLoadingGetCartItems = false;
    this.isLoadingRenewableBuyList = false;
    this.errorRenewableBuyList = error || ({} as ErrorResponse);
    this.buyListPagination = {} as IPagination;
  }

  @Mutation
  getBuyListError(error?: ErrorResponse) {
    this.isLoadingGetCartItems = false;
    this.isLoadingRenewableBuyList = false;
    this.errorRenewableBuyList = error || ({} as ErrorResponse);
    this.buyListPagination = {} as IPagination;
  }
  @Mutation
  getBuyListSuccess(data: IRenewablePaginationToBuyDTO) {
    this.renewableBuyList = [];
    this.buyListPagination = {
      CurrentPage: data.currentPage,
      Limit: PAGE_SIZE_BUY_RECS,
      TotalRow: data.totalRow,
      TotalPage: data.totalPage,
    };
    this.renewableBuyList = [...data.results];
    this.buyListPagination.Limit = PAGE_SIZE_BUY_RECS;
    this.buyListPagination.CurrentPage = data.currentPage;
    this.buyListPagination.TotalRow = data.totalRow;
    this.buyListPagination.TotalPage =
      data.totalRow % PAGE_SIZE_BUY_RECS > 0
        ? data.totalPage + 1
        : data.totalPage;
    this.isLoadingRenewableBuyList = false;
    this.errorRenewableBuyList = {} as ErrorResponse;
  }
  @Mutation
  onChangePage(currentPage: number) {
    this.buyListPagination = {
      ...this.buyListPagination,
      CurrentPage: currentPage,
    };

    this.renewableBuyList = handlePagination(
      this.tempRenewableBuyList,
      this.buyListPagination.Limit,
      this.buyListPagination.CurrentPage,
      this.buyListPagination.TotalRow
    );
  }

  @Action({ rawError: true })
  async getBuyListAction(payload: IRenewableToBuyParameters) {
    const serviceRenewableOptionAPI = new RenewableOptionAPI(
      "",
      payload.AccountId
    );
    this.setLoadingBuyList(true);
    this.emptyBuyList();
    payload.CountryCodes = this.standardsType?.find(
      (x) => x.Value === payload.Standard
    )?.CountryCodes;
    this.saveParameterRenewableOptions(payload);
    const { data, error } = await serviceRenewableOptionAPI.getBuyList(payload);

    if (!data || error) {
      this.getBuyListError(error);
    } else {
      this.getBuyListSuccess(data);
    }
  }
  @Mutation
  emptyBuyList() {
    this.renewableBuyList = [];
  }
  @Mutation
  saveParameterRenewableOptions(data: IRenewableToBuyParameters) {
    this.parameterRenewableOptions = data;
  }

  @Mutation
  addToCartFailure(error?: ErrorResponse) {
    this.isLoadingGetCartItems = false;
    ElMessage({
      message: error?.message,
      grouping: true,
      type: "error",
    });
  }
  @Mutation
  addToCartSuccess(data: BuyOderRequestDTO) {
    if (this.shoppingCart.length >= MAX_RECS) {
      ElMessage({
        message: "Limited up to 5",
        grouping: true,
        type: "warning",
      });
      return;
    }
  }
  @Action({ rawError: true })
  async addToShoppingCart(payload: BuyOderRequestDTO) {
    this.setLoadingGetCartItems(true);
    const serviceRenewableOptionAPI = new RenewableOptionAPI(
      "",
      payload.AccountId
    );
    const { data, error } = await serviceRenewableOptionAPI.addCartItem(
      payload
    );
    if (!data || error) {
      this.addToCartFailure(error);
    } else {
      await this.addToCartSuccess(payload);
      this.getCartItems(payload?.AccountId || "");
    }
  }
  @Mutation
  getItemsCartFailure(error?: ErrorResponse) {
    this.isLoadingGetCartItems = false;
    ElMessage({
      message: error?.message,
      grouping: true,
      type: "error",
    });
  }
  @Mutation
  getItemsCartSuccess(data: BuyOderItemDTO[]) {
    this.isLoadingGetCartItems = false;
    this.shoppingCart =
      data?.length > 0 ? data?.filter((x) => !x.IsExpired) : [];
  }

  @Mutation
  setLoadingGetCartItems(isLoading: boolean) {
    this.isLoadingGetCartItems = isLoading;
  }

  @Action({ rawError: true })
  async getCartItems(payload: string) {
    this.setLoadingGetCartItems(true);
    const serviceRenewableOptionAPI = new RenewableOptionAPI("", payload);
    const { data, error } = await serviceRenewableOptionAPI.getCartItem(
      payload
    );
    if (!data || error) {
      this.getItemsCartFailure(error);
    } else {
      this.getItemsCartSuccess(data);
    }
  }
  @Mutation
  removeCartItemFailure(error?: ErrorResponse) {
    this.isLoadingGetCartItems = false;
    ElMessage({
      message: error?.message,
      grouping: true,
      type: "error",
    });
  }
  @Action({ rawError: true })
  async removeToShoppingCart(payload: {
    accountId: string;
    buyOrderItemId: string;
  }) {
    this.setLoadingGetCartItems(true);
    const serviceRenewableOptionAPI = new RenewableOptionAPI();
    const { data, error } = await serviceRenewableOptionAPI.removeCartItem(
      payload
    );
    if (!data || error) {
      this.removeCartItemFailure(error);
    } else {
      this.getCartItems(payload.accountId);
    }
  }
  @Mutation
  updateShoppingCart(payload: {
    item: BuyOderItemDTO;
    value: number;
    accountId: string;
  }) {
    const { item, value } = payload;
    const idx = this.shoppingCart.findIndex(
      (el) => `${el.BuyOrderItemId}` === `${item.BuyOrderItemId}`
    );
    if (idx !== -1) {
      this.shoppingCart[idx].Quantity = value;
    }

    this.shoppingCart = [...this.shoppingCart];
  }
  @Mutation
  emptyShoppingCart() {
    this.shoppingCart = [] as BuyOderItemDTO[];
  }

  @Mutation
  emptyRetireCart() {
    this.retireCart = [] as IRenewableToBuyDTO[];
  }

  @Mutation
  getEnergyTypeError(error: ErrorResponse) {
    this.errorEnergyType = error;
  }
  @Mutation
  getEnergyTypeSuccess(data: string[]) {
    data.unshift("All");
    this.energyType = data;
    this.errorEnergyType = {} as ErrorResponse;
  }

  @Action({ rawError: true })
  async updateBuyOrderItemById(payload: {
    accountId: string;
    buyOrderItemId: string;
    quantity: number;
  }) {
    const serviceRenewableOptionAPI = new RenewableOptionAPI(
      "",
      payload.accountId
    );
    const { data, error } =
      await serviceRenewableOptionAPI.updateBuyOrderItemById(payload);
  }
  @Action({ rawError: true })
  async deleteBuyOrderItemsByOwnerId(accountId: string) {
    const serviceRenewableOptionAPI = new RenewableOptionAPI("", accountId);
    const { data, error } =
      await serviceRenewableOptionAPI.deleteBuyOrderItemsByOwnerId(accountId);
    this.getCartItems(accountId);
  }

  @Action({ rawError: true })
  async getEnergyTypeAction() {
    const serviceRenewableOptionAPI = new RenewableOptionAPI();
    const { data, error } = await serviceRenewableOptionAPI.getEnergyType();
    if (error) {
      this.getEnergyTypeError(error);
    } else {
      this.getEnergyTypeSuccess(data);
    }
  }
  get isStandardsLoadingGetter() {
    return this.isStandardsLoading;
  }
  @Mutation
  getStandardsError(error: ErrorResponse) {
    this.errorStandardsType = error;
    this.isStandardsLoading = false;
  }
  @Mutation
  getStandardsSuccess(data: IStandards[]) {
    this.standardsType = data;
    this.errorStandardsType = {} as ErrorResponse;
    this.isStandardsLoading = false;
  }
  @Mutation
  setLoadingStandards(data: boolean) {
    this.isStandardsLoading = data;
  }
  @Action({ rawError: true })
  async getStandardsAction() {
    this.setLoadingStandards(true);
    const serviceRenewableOptionAPI = new RenewableOptionAPI();
    const { data, error } = await serviceRenewableOptionAPI.getStandards();
    if (error) {
      this.getStandardsError(error);
    } else {
      this.getStandardsSuccess(data);
    }
  }

  @Mutation
  setLoadingBuyRECs(isLoading: boolean) {
    this.isLoadingBuyRECs = isLoading;
  }
  @Mutation
  submitBuyRECsSuccess() {
    this.errorBuyRECs = {} as ErrorResponse;
    this.isLoadingBuyRECs = false;
    ElMessage({
      message: "Success",
      grouping: true,
      type: "success",
    });
  }
  @Mutation
  submitBuyRECsError(error: ErrorResponse) {
    this.errorBuyRECs = error;
    this.isLoadingBuyRECs = false;
  }
  @Action({ rawError: true })
  async submitBuyRECs(payload: BuyRecDTO): Promise<boolean> {
    this.setLoadingBuyRECs(true);
    this.setLoadingGetCartItems(true);
    const serviceRenewableOptionAPI = new RenewableOptionAPI();
    const { error } = await serviceRenewableOptionAPI.submitBuyRECs(payload);

    if (error) {
      this.submitBuyRECsError(error);
      return false;
    } else {
      this.submitBuyRECsSuccess();
      this.getCartItems(payload.accountId || "");
      return true;
    }
  }

  @Mutation
  setLoadingRenewAbleOptionDetails(isLoading: boolean) {
    this.isRenewableOptionDetails = isLoading;
  }
  @Mutation
  setRenewAbleOptionDetailsSuccess(data: IBuyOrder) {
    this.errorBuyRECs = {} as ErrorResponse;
    this.isRenewableOptionDetails = false;
    let arr = [] as IRenewableOptionItemDetail[];
    arr = data?.Details?.map((item) => {
      const detail = {
        SerialNumber: item.SerialNumber,
        Registry: item.Registry,
        Vintage: item.Vintage,
        Amount: item.Amount,
        EnergyValue: item.EnergyValue,
        Source: "Purchased",
        Latitude: item.Latitude,
        Longitude: item.Longitude,
      };
      return detail;
    });

    const renewableOptionDetail = {
      FromDate: data.FromDate,
      ToDate: data.ToDate,
      Details: arr,
      Vintage: Math.max(...arr?.map((item) => item.Vintage)) || 0,
    } as IRenewableOptionDetail;
    this.renewableOptionDetails = renewableOptionDetail;
  }

  @Mutation
  setRenewAbleOptionDetailsError(error: ErrorResponse) {
    this.errorBuyRECs = error;
    this.isRenewableOptionDetails = false;
  }
  @Action({ rawError: true })
  async getRenewableOptionDetailsAction(payload: { orderId: string }) {
    const serviceRenewableOptionAPI = new RenewableOptionAPI();
    this.setLoadingRenewAbleOptionDetails(true);
    const { data, error } =
      await serviceRenewableOptionAPI.getRenewableOptionDetails(payload);
    if (error) {
      this.setRenewAbleOptionDetailsError(error);
    } else {
      this.setRenewAbleOptionDetailsSuccess(data);
    }
  }

  @Mutation
  setIsLoadingDownload(isLoading: boolean) {
    this.isLoadingDownLoad = isLoading;
  }

  @Action({ rawError: true })
  async downloadRenewableOptionsAction() {
    const serviceRenewableOptionAPI = new RenewableOptionAPI();
    this.setIsLoadingDownload(true);
    const { data, error } =
      await serviceRenewableOptionAPI.downloadRenewableOptionDetails();
    if (error) {
      this.setRenewAbleOptionDetailsError(error);
    } else {
      return data;
    }
  }
}
export default getModule(RenewableOptionsModule);
