import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex" }
const _hoisted_2 = ["disabled"]
const _hoisted_3 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!

  return (_openBlock(), _createBlock($setup["Form"], {
    as: "el-form",
    "validation-schema": $setup.schema,
    onSubmit: $setup.onSubmit,
    "label-position": "top"
  }, {
    default: _withCtx(({ isSubmitting }) => [
      _createElementVNode("div", {
        class: _normalizeClass([
        'space-y-4 2xl:space-y-6',
        isSubmitting && 'isSubmitting-rounded',
      ])
      }, [
        _createVNode($setup["Field"], { name: "Password" }, {
          default: _withCtx(({ value, field, errorMessage }) => [
            _createVNode(_component_el_form_item, {
              class: "inputfield el-form-item__error",
              error: errorMessage
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, _mergeProps({
                  type: "password",
                  placeholder: "Password"
                }, field, { "model-value": value }), null, 16, ["model-value"])
              ]),
              _: 2
            }, 1032, ["error"])
          ]),
          _: 1
        }),
        _createVNode($setup["Field"], { name: "ConfirmPassword" }, {
          default: _withCtx(({ value, field, errorMessage }) => [
            _createVNode(_component_el_form_item, {
              class: "inputfield",
              error: errorMessage
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, _mergeProps({
                  type: "password",
                  placeholder: "Confirm Password"
                }, field, { "model-value": value }), null, 16, ["model-value"])
              ]),
              _: 2
            }, 1032, ["error"])
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("button", {
            type: "reset",
            class: "w-1/2 btn bg-white text-black",
            disabled: isSubmitting
          }, " Cancel ", 8, _hoisted_2),
          _createElementVNode("button", {
            type: "submit",
            class: "w-1/2 btn btn--green-primary",
            disabled: isSubmitting
          }, " Save ", 8, _hoisted_3)
        ])
      ], 2)
    ]),
    _: 1
  }, 8, ["validation-schema"]))
}